import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import PlesySkelet from "../components/fotoPlesySkelet";
import { Link } from "gatsby";
import Layout from "../components/layout";


const Nemovitosti = () => {




    const photoList = [
        {
            id: 1,
            style: "col-4",
            preview: "nemovitosti/n/1.webp",
            full: "nemovitosti/f/1.jpg",
            alt: "porfolio fotka nemovitostí",
        },
        {
            id: 2,
            style: "col-4",
            preview: "nemovitosti/n/2.webp",
            full: "nemovitosti/f/2.jpg",
            alt: "porfolio fotka nemovitostí",
        },
        {
            id: 3,
            style: "col-4",
            preview: "nemovitosti/n/3.webp",
            full: "nemovitosti/f/3.jpg",
            alt: "porfolio fotka nemovitostí",
        },
        {
            id: 4,
            style: "col-12",
            preview: "nemovitosti/n/4.webp",
            full: "nemovitosti/f/4.jpg",
            alt: "porfolio fotka nemovitostí",
        },
        {
            id: 5,
            style: "col-12",
            preview: "nemovitosti/n/5.webp",
            full: "nemovitosti/f/5.jpg",
            alt: "porfolio fotka nemovitostí",
        },
        {
            id: 6,
            style: "col-12",
            preview: "nemovitosti/n/6.webp",
            full: "nemovitosti/f/6.jpg",
            alt: "porfolio fotka nemovitostí",
        },
        {
            id: 7,
            style: "col-12",
            preview: "nemovitosti/n/7.webp",
            full: "nemovitosti/f/7.jpg",
            alt: "porfolio fotka nemovitostí",
        },
        {
            id: 8,
            style: "col-12",
            preview: "nemovitosti/n/8.webp",
            full: "nemovitosti/f/8.jpg",
            alt: "porfolio fotka nemovitostí",
        },
        {
            id: 9,
            style: "col-12",
            preview: "nemovitosti/n/9.webp",
            full: "nemovitosti/f/9.jpg",
            alt: "porfolio fotka nemovitostí",
        },
        {
            id: 10,
            style: "col-12",
            preview: "nemovitosti/n/10.webp",
            full: "nemovitosti/f/10.jpg",
            alt: "porfolio fotka nemovitostí",
        },
        {
            id: 11,
            style: "col-12",
            preview: "nemovitosti/n/11.webp",
            full: "nemovitosti/f/11.jpg",
            alt: "porfolio fotka nemovitostí",
        },
        {
            id: 12,
            style: "col-4",
            preview: "nemovitosti/n/12.webp",
            full: "nemovitosti/f/12.jpg",
            alt: "porfolio fotka nemovitostí",
        },
        {
            id: 13,
            style: "col-4",
            preview: "nemovitosti/n/13.webp",
            full: "nemovitosti/f/13.jpg",
            alt: "porfolio fotka nemovitostí",
        },
        {
            id: 14,
            style: "col-4",
            preview: "nemovitosti/n/14.webp",
            full: "nemovitosti/f/14.jpg",
            alt: "porfolio fotka nemovitostí",
        }
    ];


    const videos = [
        {
            id: 1,
            style: "col-12 col-lg-6",
            preview: "plesy/video1.webp",
            full: "https://www.youtube.com/watch?v=h2Oeems2m0Q",
            alt: "porfolio fotka nemovitostí",
            desc: "Půlnoční překvapení | SOŠPg Nová Paka",
        },
        {
            id: 2,
            style: "col-12 col-lg-6",
            preview: "plesy/video2.webp",
            full: "https://www.youtube.com/watch?v=apQKqAmxSIs",
            alt: "porfolio fotka nemovitostí",
            desc: "Maturitní ples | SOŠPg Nová Paka",
        }
    ];




    const helmetElement = (
        <Helmet>
            <title>Nemovitosti | Martin Petrovický | Foto a video</title>
            <meta
                name="description"
                content="Ukázka plesových fotografií a videí."
            />
            <meta
                name="keywords"
                content="Ples, Maturita, fotograf, kameraman, maturitní video, maturitní fotograf, plesový kameraman, maturitní kameraman, prom"
            />
            <meta name="author" content="Martin Petrovický" />
        </Helmet>
    );


    const togglePack = (packId) => {
        var paragraph = document.querySelector(`.pp${packId}`);
        paragraph.classList.toggle("expand-pack");
        var icon = document.querySelector(`.icon${packId}`);
        icon.classList.toggle("icon-expand");
    };

    const handleImageClick = () => {
        document
            .getElementById("svatby_header")
            .scrollIntoView({ behavior: "smooth", block: "start" });
    };


    return (
        <Layout>
            <main>
                {helmetElement}
                <div className="container">
                    <div className="text-center h1 mt-5 mb-4 ">Focení nemovitostí</div>

                    <PlesySkelet photoList={photoList} />

                    {/* <span id="prom_video" >
                        <VideoSkelet videos={videos} />
                    </span> */}


                    <div className="row text-center py-5">
                        <div className="col-12 col-lg-6 text-left">
                        <h2 className="mb-3">CO MŮŽETE OČEKÁVAT?</h2>
                            <ul className="list-dots">
                                <li>Pomohu Vám zařídit prezentaci vaší nemovitosti pro výhodnější a snadnější prodej</li>
                                <li className="my-2">Obdžíte upravené fotografie/video v digitální podobě</li>
                                <li >Lze doplnit o záběry z dronu s případnou info grafikou (např. oznaření pozemku)</li> 
                                <li className="my-2">Pro létání s dronem mám platné pilotní zkoušky</li>                               
                                <li>Cestové 10 kč/kilometr ( z Chlumce nad Cidlinou)</li>
                                <li className="my-2">Budu se těšit na společnou spolupráci s Vámi</li>
                            </ul>
                        </div>

                        <div className="col-12 col-lg-6 mt-5 mt-md-0">
                            <h2 className="mb-3">CENA</h2>
                            <div>
                                <h6 className="cursor-pointer" onClick={() => togglePack(5)}>
                                    FOCENÍ
                                    <FontAwesomeIcon
                                        className="ml-2 align-middle icon icon5"
                                        icon={faChevronDown}
                                    />
                                </h6>
                                <p className="price-pack pp5  p-0">
                                    <b>CENA: od 2 500 Kč</b>
                                </p>
                            </div>
                            <div>
                                <h6 className="cursor-pointer" onClick={() => togglePack(6)}>
                                VIDEO
                                    <FontAwesomeIcon
                                        className="ml-2 align-middle icon icon6"
                                        icon={faChevronDown}
                                    />
                                </h6>
                                <p className="price-pack pp6  p-0">
                                    <b>CENA: od 4 000 Kč</b>
                                </p>
                            </div>
                            <Link to="/kontakt">
                                <div className="btn btn-svatby rounded-0 border border-dark border-end-0 border-start-0 mt-3 mb-5">
                                    Napište mi
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export default Nemovitosti
