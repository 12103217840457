import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import ImageFadeIn from "react-image-fade-in";


const PlesySkelet = (props) => {

    const [isVisible, setIsVisible] = useState(false);
    const [slide, setSlide] = useState(0);

    const showSlide = (slide) => {
        setIsVisible(!isVisible);
        setSlide(slide);
    };

    const image = (props) => (
        <ImageFadeIn
            opacityTransition={1.5}
            className={props.style + " py-1 py-lg-3 px-1 px-lg-3 h-auto"}
            src={require(`../../static/images/${props.preview}`).default}
            alt={props.alt}
            onClick={() => showSlide(props.id)}
        />
    );

    return (

        <div>
            <FsLightbox
                toggler={isVisible}
                slide={slide}
                sources={props.photoList.map((item) => require(`../../static/images/${item.full}`).default)}
            />

            <div>
                {/* prvni vodorvna */}
                <div className="row">
                    {image(props.photoList[0])}
                    {image(props.photoList[1])}
                    {image(props.photoList[2])}
                </div>
                {/* prvni řada sloupců  */}
                <div className="row">
                    {/* první sloupec  */}
                    <div className="col-4 p-0">
                        {image(props.photoList[3])}
                        {image(props.photoList[4])}

                        {image(props.photoList[5])}
                        
                    </div>
                    {/* druhy sloupec */}
                    <div className="col-5 p-0">
                        {image(props.photoList[6])}
                        {image(props.photoList[7])}
                    </div>
                    {/* treti sloupec */}
                    <div className="col-3 p-0">
                        {image(props.photoList[8])}
                        {image(props.photoList[9])}
                        {image(props.photoList[10])}
                    </div>
                </div>
                <div className="row">
                    {image(props.photoList[11])}
                    {image(props.photoList[12])}
                    {image(props.photoList[13])}
                </div>

            </div>
        </div>
    )
};

export default PlesySkelet;
